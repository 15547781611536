/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.css"
import MobileHeader from "./mobile/header"
import Footer from "./footer/footer"
import "./layout.css"
import "../pages/index.css"

import SEO from "./seo"
import Sidebar from "./sidebar/Sidebar"

import { CSSTransition } from 'react-transition-group';

const breakpoint = 960;

const Layout = ({ children, title, description, image }) => {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  const [showSidebar, setShowSidebar] = useState(width >= breakpoint)
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    setShowSidebar(width >= breakpoint);
  }, [width]);

  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            tagline
            author
            contacts {
              linkedin
              github
              stackoverflow
              youtube
              twitter
            }
          }
        }
      }
    `}
      render={data => (
        <div
          style={{
            backgroundColor: 'var(--bg)',
            color: 'var(--text)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out',
            minHeight: '100vh',
          }}
        >
          <main id="content-wrap" className={`${showSidebar && width < breakpoint ? 'mobile-sidebar-active' : ''}`}>
            <SEO title={title} description={description} lang="es" image={image} />
            {
              width < breakpoint &&
              <MobileHeader author={data.site.siteMetadata.author} setShowSidebar={setShowSidebar} />
            }
            <div className="index-main">
              <CSSTransition
                in={showSidebar}
                timeout={500}
                unmountOnExit
                appear
                classNames="transition"
              >
                <div id="sidebar" className={`sidebar ${width < breakpoint ? 'fullPage' : ''}`}>
                  <Sidebar setShowSidebar={setShowSidebar} />
                </div>
              </CSSTransition>
              {children}
            </div>
          </main>
          <Footer author={data.site.siteMetadata.author} contacts={data.site.siteMetadata.contacts} />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
