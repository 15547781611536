import React from "react"
import { Link } from "gatsby";
import "./tags.css"

const TechTag = ({ active = false, tag, tech, name, size, color }) => {
    return (
        <div className="group-tags d-inline-block ml-0 mr-1 my-1">
            <Link to={`/categoria/${tag}/`} >
                <button className="tech-tag text-white" style={{ opacity: active ? '1' : '0.75' }}>
                    <p className="d-inline mr-2">{tech}</p>
                    <div className="d-inline" style={{ fontSize: size, color: color }}>
                        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={size} height={size} style={{ fill: `${color}` }}>
                            <title>{tech}</title>
                            <path d={name} />
                        </svg>
                    </div>
                </button>
            </Link>
        </div>
    )
}

export default TechTag