import React from "react"
import { Link } from "gatsby"
import SocialLinks from "../SocialLinks"
import "./footer.css"

const Footer = ({ author, contacts }) => {
  return (
    <footer id="footer" className="p-2">
      <p className="mb-2 mr-3">© {new Date().getFullYear()} · {author} · <Link className="footer-link" rel="noopener noreferrer" to="/legal">Legal</Link></p>
      <SocialLinks contacts={contacts} />
    </footer>
  )
}

export default Footer
