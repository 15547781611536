import React from "react"
import { Link } from "gatsby"
import { renderTags, dateToString } from '../utils'

const SinglePostList = ({ id, date, title, slug, tags, excerpt }) => {
  return (
    <article key={id} className="container mt-3 mb-5">
      <hr className="w-25"></hr>
      <small className="d-block text-muted mb-1"><i>{dateToString(date)}</i></small>
      <Link to={slug} className="text-dark">
        <h3 className="title">{title}</h3>
      </Link>
      <div className="d-block mb-3">
        {renderTags(tags)}
      </div>
      <p className="my-2">{excerpt}</p>
      <Link to={slug} className="text-dark d-inline-block font-weight-bold">→ Ver artículo</Link>
    </article>
  )
}

export default SinglePostList
