import React from "react"
import {
    FaLinkedin,
    FaGithubSquare,
    FaStackOverflow,
    FaYoutube,
    FaTwitterSquare
} from "react-icons/fa"

const SocialLinks = ({ contacts: { linkedin, github, stackoverflow, youtube, twitter } }) => {
    const size = 26
    return (
        <div className="social-links">
            <a className="text-secondary p-2" href={linkedin}>
                <span title="Linked In"><FaLinkedin size={size} /></span>
            </a>
            <a className="text-secondary p-2" href={github}>
                <span title="GitHub"><FaGithubSquare size={size} /></span>
            </a>
            <a className="text-secondary p-2" href={stackoverflow}>
                <span title="Stack Overflow"><FaStackOverflow size={size} /></span>
            </a>
            <a className="text-secondary p-2" href={youtube}>
                <span title="Youtube"><FaYoutube size={size} /></span>
            </a>
            <a className="text-secondary p-2" href={twitter}>
                <span title="Twitter"><FaTwitterSquare size={size} /></span>
            </a>
        </div>
    )
}

export default SocialLinks