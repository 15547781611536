import React from "react"
import ProfileImage from "../profile-image"
import "./header.css"

const Header = ({ author, setShowSidebar }) => {
  const handleImageClick = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setShowSidebar(true);
  }
  return (
    <button id="mobile-header-button" onClick={handleImageClick} onKeyDown={handleImageClick}>
      <header className="mobile-header px-3">
        <h3 className="mb-0">{author}</h3>
        <ProfileImage />
      </header>
    </button>
  )
}

export default Header
