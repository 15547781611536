import React from 'react'
import TechTag from './components/tags/TechTag'
import SinglePostList from './components/single-post-list'
import { labels } from '../config'

export const dateToString = (date) => {
  return new Date(date).toLocaleDateString('es-ES', { day: 'numeric', year: 'numeric', month: 'long' })
}

export const renderTags = (postTags, currentTag = null) => {
  return labels
    .filter(({ tag }) => postTags.includes(tag))
    .map(({ tag, tech, name, size, color }) => {
      return (
        <TechTag key={tag} tag={tag} tech={tech} name={name} size={size} color={color} active={currentTag === tag} />
      )
    })
}

export const renderPostsList = (posts) => {
  return posts.map((post) => {
    const { tags, title, date } = post.node.frontmatter
    const { id, excerpt, fields: { slug } } = post.node
    return (
      <SinglePostList key={id} id={id} date={date} title={title} slug={slug} tags={tags} excerpt={excerpt} />
    )
  })
}