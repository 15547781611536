import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ProfileImage = () => {
  const { avatar, image, site } = useStaticQuery(query);
  return <div className="profile-images">
    <Img fluid={avatar.childImageSharp.fluid} className="profile-img" alt={site.siteMetadata.author} />
    <Img fluid={image.childImageSharp.fluid} className="profile-img animation" alt={site.siteMetadata.author} />
  </div>
}

const query = graphql`
      query {
        avatar: file(relativePath: { eq: "profile-avatar.png" }) {
          childImageSharp {
            fluid(maxWidth: 120) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image: file(relativePath: { eq: "profile-image.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 120) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        site {
          siteMetadata {
            author
          }
        }
      }
    `;

export default ProfileImage
