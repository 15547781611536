import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import ProfileImage from "../profile-image"
import "./sidebar.css"
import SocialLinks from "../SocialLinks"
import { renderTags } from '../../utils'

import { FaArrowLeft, FaMoon, FaSun } from 'react-icons/fa'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

const Sidebar = ({ setShowSidebar }) => {
    const { data, postsDataTags } = useStaticQuery(query);
    const { author, tagline, tagline2, contacts, menu } = data.siteMetadata;
    const currentLocation = window.location.pathname.split('/');
    const currentTag = currentLocation[1] === 'categoria' ? currentLocation[2] : null;
    const postsTags = getPostsTags(postsDataTags.edges)
    return (
        <aside className="sidebar-main pl-4">
            <div className="bio-main pr-2 pb-3">
                <ProfileImage />
                <h2 className="mt-2 mb-2 author-bio">{author}</h2>
                <button id="close-sidebar" onClick={() => setShowSidebar(false)} title="close">
                    <FaArrowLeft size={30} />
                </button>
                <small className="text-muted">{tagline}</small>
                <br></br>
                <small className="text-muted">{tagline2}</small>
            </div>
            <SocialLinks contacts={contacts} />
            <nav className="page-links mt-4 mb-4">
                {renderMenu(menu)}
            </nav>
            <ThemeToggler>
                {({ theme, toggleTheme }) => {
                    if (theme == null) {
                        return null
                    }
                    return (
                        <button id="dark-mode-btn">
                            <input
                                id="dark-mode"
                                type="checkbox"
                                onChange={(e) =>
                                    toggleTheme(e.target.checked ? 'dark' : 'light')
                                }
                                checked={theme === 'dark'}
                            />
                            <label htmlFor="dark-mode">
                                {theme === 'light' ? <FaMoon size={30} /> : <FaSun size={30} />}
                            </label>
                        </button>
                    )
                }}
            </ThemeToggler>
            <div className="d-block mt-4 mr-2">
                {renderTags(postsTags, currentTag)}
            </div>
        </aside>
    )
}

const getPostsTags = (postsDataTags) => {
    const postsTags = new Set();
    postsDataTags.forEach(post => {
        post.node.frontmatter.tags.forEach(tag => postsTags.add(tag))
    })
    return Array.from(postsTags)
}

const renderMenu = (menu) => {
    return menu.map(({ slug, title }) => {
        return (
            <Link key={slug} to={slug}>
                <span className={`text-dark d-block py-2 ${window.location.pathname === slug ? 'active' : ''}`}>{title}</span>
            </Link>
        )
    })
}

const query = graphql`
                query SidebarQuery {
                    data: site {
                        siteMetadata {
                            title
                            tagline
                            tagline2
                            author
                            contacts {
                                linkedin
                                github
                                stackoverflow
                                youtube
                                twitter
                            }
                            menu {
                                slug
                                title
                            }
                        }
                    }
                    postsDataTags: allMarkdownRemark (
                        sort: { fields: [frontmatter___date], order: DESC }
                        filter: { frontmatter: { published: { eq: true } } }
                    ) {
                        totalCount
                        edges {
                            node {
                                frontmatter {
                                    tags
                                }
                            }
                        }
                    }
                }
`

export default Sidebar